<script setup lang="ts">
import Item from "~/components/Redmonkey/Courses/Item.vue";
import TitleBase from "~/components/Redmonkey/Base/TitleBase.vue";
import CategoryFilter from "~/components/Redmonkey/Filter/CategoryFilter.vue";

const props = defineProps({
  courses: {
    type: Object,
    required: true,
    default: {nodes: []}
  },
  title: {
    type: String,
    required: true,
    default: ''
  },
  button: {
    type: Object,
    default: {
      component: 'a',
      url: null,
      title: null,
      target: null
    }
  }
})

const coursesRef = ref(props.courses);
const isNextPageExist = computed(() => coursesRef.value.pageInfo?.hasNextPage);
const activeCategoryNameFilter = ref('');

const updateCourses = async (params: object, merge = true) => {
  const {data: courses} = await $fetch('/api/wordpress/getCourses', {
    method: 'POST',
    body: params
  });

  if (courses?.nodes) {
    let nodes = courses.nodes;

    if(merge) {
      nodes = [
        ...coursesRef.value.nodes,
        ...courses.nodes
      ]
    }

    coursesRef.value = {
      nodes: nodes,
      pageInfo: courses.pageInfo
    }
  }
}

const loadMore = async () => {
  if (!coursesRef.value?.pageInfo?.endCursor) return;

  await updateCourses({
    after: coursesRef.value.pageInfo.endCursor,
    categoryName: activeCategoryNameFilter.value
  });
}

const refetch =  async (categoryName = '') => {
  activeCategoryNameFilter.value = categoryName;
  await updateCourses({
    categoryName: activeCategoryNameFilter.value
  }, false)
}
</script>
<template>
  <div class="courses-list courses-all">
    <div class="page-title">
      <TitleBase :title="title" position="left" star-position="right"/>
    </div>
    <CategoryFilter type="courses" :fetch="refetch" :active-name="activeCategoryNameFilter"/>
    <div class="decorate-hexagon">
      <img src="/images/hexagon.png" :alt="title"/>
    </div>
    <div class="courses-items flex wrap">
      <template v-for="(course, index) in coursesRef.nodes" :key="course.id">
        <Item
            :title="course.title"
            :content="course.courses.smallDescription"
            :age="course.courses.age"
            :price="course.courses.price"
            :url="course.uri"
        />
      </template>
    </div>
    <div class="all-courses-button" v-if="isNextPageExist">
      <a class="action primary" href="#" @click.prevent="loadMore()">
        {{ button.title }}
      </a>
    </div>
  </div>
</template>