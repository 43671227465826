<script setup lang="ts">
import {useElementor, useWp} from "~/composables/Redmonkey";
import ListAll from "~/components/Redmonkey/Courses/ListAll.vue";
import ListShort from "~/components/Redmonkey/Courses/ListShort.vue";
import ListNotCourse from "~/components/Redmonkey/Courses/ListNotCourse.vue";
import type {ElementorElement} from "~/integration/wordpress/types";
import type {ComponentPublicInstance} from "@vue/runtime-core";
import {NuxtLink} from "#components";
import type {PropType} from "vue";

let Element = ListShort as ComponentPublicInstance | unknown;
let variables = {};

const props = defineProps({
  element: {
    type: Object as PropType<ElementorElement>,
    required: true
  }
})

const {
  getSettingsValue,
  isLinkTargetBlank
} = useElementor();

const {
  isExternalLink
} = useWp();


if (getSettingsValue(props?.element, 'five_or_six')) {
  Element = ListAll;
}

const courseSelectedManual = getSettingsValue(props?.element, 'choose_courses_manual');
if (courseSelectedManual) {
  if (courseSelectedManual.length) {
    Element = ListNotCourse;
  }
}

const courseSelected = getSettingsValue(props?.element, 'choose_courses');
if (courseSelected) {
  if (courseSelected.length) {
    variables = {
      ids: courseSelected.split(',')
    }
  }
}


const {data: coursesData} = await useFetch('/api/wordpress/getCourses', {
  method: "post",
  body: variables
});

const buttonUrl = getSettingsValue(props?.element, 'courses_all_button_url') || '/courses';

const button = {
  url: buttonUrl,
  target: isLinkTargetBlank('courses_all_button_url') ? '_blank' : '_self',
  title: getSettingsValue(props?.element, 'courses_all_button_text') || 'Більше курсів',
  component: isExternalLink(buttonUrl) ? 'a' : NuxtLink
}
</script>
<template>
  <div class="courses-list">
    <component
        :is="Element"
        :courses="coursesData.courses"
        :title="getSettingsValue(element, 'title')"
        :button="button"
    />
  </div>
</template>

<style lang="scss">
  @import "assets/css/source/components/widgets/courses";
</style>